import revive_payload_client_PQ8IX9cPXp from "/home/node/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_dybdmy63zptf2a7o5vvgseh7ma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CcpPoSOCpS from "/home/node/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_dybdmy63zptf2a7o5vvgseh7ma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VXCWLhorUO from "/home/node/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_dybdmy63zptf2a7o5vvgseh7ma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_2hw3SWBi2L from "/home/node/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_dybdmy63zptf2a7o5vvgseh7ma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ozQTNCecBJ from "/home/node/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_dybdmy63zptf2a7o5vvgseh7ma/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aOvEX0BhLy from "/home/node/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/.nuxt/components.plugin.mjs";
import prefetch_client_vSygO5SSvD from "/home/node/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_dybdmy63zptf2a7o5vvgseh7ma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import gsapPlugin_HlwIIyl85L from "/home/node/.nuxt/gsapPlugin.mjs";
import i18n_4gMi5uGCu8 from "/home/node/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_FSxT4145LW from "/home/node/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_dybdmy63zptf2a7o5vvgseh7ma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_TMFOCxwI0b from "/home/node/node_modules/.pnpm/@vueuse+nuxt@10.10.0_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@2_odzc2ronbdzlkzxledw5gqaebu/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import gtm_cXsUZobmSF from "/home/node/plugins/gtm.ts";
export default [
  revive_payload_client_PQ8IX9cPXp,
  unhead_CcpPoSOCpS,
  router_VXCWLhorUO,
  payload_client_2hw3SWBi2L,
  check_outdated_build_client_ozQTNCecBJ,
  plugin_vue3_aOvEX0BhLy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vSygO5SSvD,
  gsapPlugin_HlwIIyl85L,
  i18n_4gMi5uGCu8,
  chunk_reload_client_FSxT4145LW,
  ssr_plugin_TMFOCxwI0b,
  gtm_cXsUZobmSF
]