import { default as _91_46_46_46slug_93RFGIAO9iqJMeta } from "/home/node/pages/[...slug].vue?macro=true";
import { default as about_45usNTBV1zkbhdMeta } from "/home/node/pages/about-us.vue?macro=true";
import { default as analyticstlp1mYD8IkMeta } from "/home/node/pages/analytics.vue?macro=true";
import { default as appwjv4HiBfy4Meta } from "/home/node/pages/app.vue?macro=true";
import { default as indexjBvbyAgHxEMeta } from "/home/node/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93RByZRJHRnBMeta } from "/home/node/pages/posts/[...slug].vue?macro=true";
import { default as indexMuTsXnmP90Meta } from "/home/node/pages/posts/index.vue?macro=true";
import { default as privacy_45policyRkHPYIFni5Meta } from "/home/node/pages/privacy-policy.vue?macro=true";
import { default as registrar_45listzNl3zlYsoAMeta } from "/home/node/pages/registrar-list.vue?macro=true";
import { default as suppliers1QlYv1MyngMeta } from "/home/node/pages/suppliers.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93RFGIAO9iqJMeta?.name ?? "slug___ar",
    path: _91_46_46_46slug_93RFGIAO9iqJMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93RFGIAO9iqJMeta || {},
    alias: _91_46_46_46slug_93RFGIAO9iqJMeta?.alias || [],
    redirect: _91_46_46_46slug_93RFGIAO9iqJMeta?.redirect,
    component: () => import("/home/node/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RFGIAO9iqJMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93RFGIAO9iqJMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93RFGIAO9iqJMeta || {},
    alias: _91_46_46_46slug_93RFGIAO9iqJMeta?.alias || [],
    redirect: _91_46_46_46slug_93RFGIAO9iqJMeta?.redirect,
    component: () => import("/home/node/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: about_45usNTBV1zkbhdMeta?.name ?? "about-us___ar",
    path: about_45usNTBV1zkbhdMeta?.path ?? "/about-us",
    meta: about_45usNTBV1zkbhdMeta || {},
    alias: about_45usNTBV1zkbhdMeta?.alias || [],
    redirect: about_45usNTBV1zkbhdMeta?.redirect,
    component: () => import("/home/node/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usNTBV1zkbhdMeta?.name ?? "about-us___en",
    path: about_45usNTBV1zkbhdMeta?.path ?? "/en/about-us",
    meta: about_45usNTBV1zkbhdMeta || {},
    alias: about_45usNTBV1zkbhdMeta?.alias || [],
    redirect: about_45usNTBV1zkbhdMeta?.redirect,
    component: () => import("/home/node/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: analyticstlp1mYD8IkMeta?.name ?? "analytics___ar",
    path: analyticstlp1mYD8IkMeta?.path ?? "/analytics",
    meta: analyticstlp1mYD8IkMeta || {},
    alias: analyticstlp1mYD8IkMeta?.alias || [],
    redirect: analyticstlp1mYD8IkMeta?.redirect,
    component: () => import("/home/node/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: analyticstlp1mYD8IkMeta?.name ?? "analytics___en",
    path: analyticstlp1mYD8IkMeta?.path ?? "/en/analytics",
    meta: analyticstlp1mYD8IkMeta || {},
    alias: analyticstlp1mYD8IkMeta?.alias || [],
    redirect: analyticstlp1mYD8IkMeta?.redirect,
    component: () => import("/home/node/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: appwjv4HiBfy4Meta?.name ?? "app___ar",
    path: appwjv4HiBfy4Meta?.path ?? "/app",
    meta: appwjv4HiBfy4Meta || {},
    alias: appwjv4HiBfy4Meta?.alias || [],
    redirect: appwjv4HiBfy4Meta?.redirect,
    component: () => import("/home/node/pages/app.vue").then(m => m.default || m)
  },
  {
    name: appwjv4HiBfy4Meta?.name ?? "app___en",
    path: appwjv4HiBfy4Meta?.path ?? "/en/app",
    meta: appwjv4HiBfy4Meta || {},
    alias: appwjv4HiBfy4Meta?.alias || [],
    redirect: appwjv4HiBfy4Meta?.redirect,
    component: () => import("/home/node/pages/app.vue").then(m => m.default || m)
  },
  {
    name: indexjBvbyAgHxEMeta?.name ?? "index___ar",
    path: indexjBvbyAgHxEMeta?.path ?? "/",
    meta: indexjBvbyAgHxEMeta || {},
    alias: indexjBvbyAgHxEMeta?.alias || [],
    redirect: indexjBvbyAgHxEMeta?.redirect,
    component: () => import("/home/node/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjBvbyAgHxEMeta?.name ?? "index___en",
    path: indexjBvbyAgHxEMeta?.path ?? "/en",
    meta: indexjBvbyAgHxEMeta || {},
    alias: indexjBvbyAgHxEMeta?.alias || [],
    redirect: indexjBvbyAgHxEMeta?.redirect,
    component: () => import("/home/node/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RByZRJHRnBMeta?.name ?? "posts-slug___ar",
    path: _91_46_46_46slug_93RByZRJHRnBMeta?.path ?? "/posts/:slug(.*)*",
    meta: _91_46_46_46slug_93RByZRJHRnBMeta || {},
    alias: _91_46_46_46slug_93RByZRJHRnBMeta?.alias || [],
    redirect: _91_46_46_46slug_93RByZRJHRnBMeta?.redirect,
    component: () => import("/home/node/pages/posts/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RByZRJHRnBMeta?.name ?? "posts-slug___en",
    path: _91_46_46_46slug_93RByZRJHRnBMeta?.path ?? "/en/posts/:slug(.*)*",
    meta: _91_46_46_46slug_93RByZRJHRnBMeta || {},
    alias: _91_46_46_46slug_93RByZRJHRnBMeta?.alias || [],
    redirect: _91_46_46_46slug_93RByZRJHRnBMeta?.redirect,
    component: () => import("/home/node/pages/posts/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexMuTsXnmP90Meta?.name ?? "posts___ar",
    path: indexMuTsXnmP90Meta?.path ?? "/posts",
    meta: indexMuTsXnmP90Meta || {},
    alias: indexMuTsXnmP90Meta?.alias || [],
    redirect: indexMuTsXnmP90Meta?.redirect,
    component: () => import("/home/node/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: indexMuTsXnmP90Meta?.name ?? "posts___en",
    path: indexMuTsXnmP90Meta?.path ?? "/en/posts",
    meta: indexMuTsXnmP90Meta || {},
    alias: indexMuTsXnmP90Meta?.alias || [],
    redirect: indexMuTsXnmP90Meta?.redirect,
    component: () => import("/home/node/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyRkHPYIFni5Meta?.name ?? "privacy-policy___ar",
    path: privacy_45policyRkHPYIFni5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyRkHPYIFni5Meta || {},
    alias: privacy_45policyRkHPYIFni5Meta?.alias || [],
    redirect: privacy_45policyRkHPYIFni5Meta?.redirect,
    component: () => import("/home/node/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyRkHPYIFni5Meta?.name ?? "privacy-policy___en",
    path: privacy_45policyRkHPYIFni5Meta?.path ?? "/en/privacy-policy",
    meta: privacy_45policyRkHPYIFni5Meta || {},
    alias: privacy_45policyRkHPYIFni5Meta?.alias || [],
    redirect: privacy_45policyRkHPYIFni5Meta?.redirect,
    component: () => import("/home/node/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registrar_45listzNl3zlYsoAMeta?.name ?? "registrar-list___ar",
    path: registrar_45listzNl3zlYsoAMeta?.path ?? "/registrar-list",
    meta: registrar_45listzNl3zlYsoAMeta || {},
    alias: registrar_45listzNl3zlYsoAMeta?.alias || [],
    redirect: registrar_45listzNl3zlYsoAMeta?.redirect,
    component: () => import("/home/node/pages/registrar-list.vue").then(m => m.default || m)
  },
  {
    name: registrar_45listzNl3zlYsoAMeta?.name ?? "registrar-list___en",
    path: registrar_45listzNl3zlYsoAMeta?.path ?? "/en/registrar-list",
    meta: registrar_45listzNl3zlYsoAMeta || {},
    alias: registrar_45listzNl3zlYsoAMeta?.alias || [],
    redirect: registrar_45listzNl3zlYsoAMeta?.redirect,
    component: () => import("/home/node/pages/registrar-list.vue").then(m => m.default || m)
  },
  {
    name: suppliers1QlYv1MyngMeta?.name ?? "suppliers___ar",
    path: suppliers1QlYv1MyngMeta?.path ?? "/suppliers",
    meta: suppliers1QlYv1MyngMeta || {},
    alias: suppliers1QlYv1MyngMeta?.alias || [],
    redirect: suppliers1QlYv1MyngMeta?.redirect,
    component: () => import("/home/node/pages/suppliers.vue").then(m => m.default || m)
  },
  {
    name: suppliers1QlYv1MyngMeta?.name ?? "suppliers___en",
    path: suppliers1QlYv1MyngMeta?.path ?? "/en/suppliers",
    meta: suppliers1QlYv1MyngMeta || {},
    alias: suppliers1QlYv1MyngMeta?.alias || [],
    redirect: suppliers1QlYv1MyngMeta?.redirect,
    component: () => import("/home/node/pages/suppliers.vue").then(m => m.default || m)
  }
]