export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"السجل العقاري نافذتك لإدارة ملكياتك العقارية وتقديم خدمات التسجيل العيني والتصرفات العقارية مثل نقل الملكية، الفرز، الدمج، وإدارة الحقوق والقيود والالتزامات. يهدف السجل إلى تعزيز الشفافية والثقة في السوق العقاري من خلال توفير معلومات محدثة ودقيقة للملاك والمستثمرين."},{"hid":"keywords","name":"keywords","content":"السجل العقاري, العقار, تسجيل عيني, تصرفات عقارية, نقل ملكية, فرز, دمج, حقوق, قيود, الالتزامات العقارية, شفافية, ثقة, سوق عقاري, معلومات, ملاك, مستثمرين, المملكة العربية السعودية, الرياض, جدة, الدمام, مكة, المدينة, الطائف, الخبر, الجبيل, الخرج, الظهران, الأحساء, القصيم, حائل, الجوف, الباحة, عسير, جازان, نجران, تبوك, الحدود الشمالية, الجنوبية, الشرقية, الغربية"}],"link":[{"rel":"icon","type":"image/png","href":"favicon.ico"}],"style":[],"script":[],"noscript":[]}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false